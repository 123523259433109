import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDfxlzE6weu6AyHmJ9Fe9ddFm670MxZKM0",
    authDomain: "feed-production.firebaseapp.com",
    databaseURL: "https://feed-production.firebaseio.com",
    projectId: "feed-production",
    storageBucket: "feed-production.appspot.com",
    messagingSenderId: "924022935874",
    appId: "1:924022935874:web:9c41eaee4cbcdbe53438db"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { app, auth };


