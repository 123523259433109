import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const Header = ({ onLogout, idToken, farmLocations, userData }) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuOpen = () => {
        setDrawerOpen(true);
    };

    const handleMenuClose = () => {
        setDrawerOpen(false);
    };

    const handleCopyIdToken = async () => {
        try {
            await navigator.clipboard.writeText(idToken);
            alert('ID Token copied to clipboard');
        } catch (err) {
            console.error('Failed to copy ID token:', err);
        }
    };

    // Group farms by country, state, and city
    const groupedFarms = farmLocations.reduce((acc, farm) => {
        const { country, state, city } = farm;  // Assuming these are returned by the API
        if (!acc[country]) acc[country] = {};
        if (!acc[country][state]) acc[country][state] = {};
        if (!acc[country][state][city]) acc[country][state][city] = [];
        acc[country][state][city].push(farm);
        return acc;
    }, {});

    // Extract first letter of user name
    const avatarLetter = userData?.name?.[0]?.toUpperCase() || '';

    return (
        <>
            <AppBar position="fixed" sx={{ backgroundColor: '#333' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Hamburger Menu */}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleMenuOpen}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Centered Title */}
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        Farm Dashboard
                    </Typography>

                    {/* User Menu */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="User Avatar" >{avatarLetter}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem>{userData.name}</MenuItem>
                            {/* Copy ID Token Menu Item */}
                            <MenuItem
                                onClick={() => {
                                    handleCopyIdToken();
                                    handleCloseUserMenu();
                                }}
                            >
                                <Typography textAlign="center">Copy ID Token</Typography>
                            </MenuItem>

                            {/* Logout Menu Item */}
                            <MenuItem
                                onClick={() => {
                                    onLogout();
                                    handleCloseUserMenu();
                                }}
                            >
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* Drawer for Farms */}
            <Drawer anchor="left" open={drawerOpen} onClose={handleMenuClose}>
                <Box sx={{ width: 250 }} role="presentation">
                    {/* Add My Farms Typography */}
                    <Typography variant="h6" sx={{ p: 2 }}>
                        My Farms
                    </Typography>

                    {Object.keys(groupedFarms).map((country) => (
                        <Accordion key={country}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{country}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Object.keys(groupedFarms[country]).map((state) => (
                                    <Accordion key={state}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ pl: 2 }}>
                                            <Typography>{state}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {Object.keys(groupedFarms[country][state]).map((city) => (
                                                <Accordion key={city}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ pl: 4 }}>
                                                        <Typography>{city}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        {groupedFarms[country][state][city].map((farm) => (
                                                            <ListItemText key={farm.id} primary={farm.name} sx={{ pl: 6 }} />
                                                        ))}
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Drawer>
        </>
    );
};

export default Header;
