import React, { useMemo, useState, useCallback, useEffect } from "react";
import Map, { Source, Layer, Popup, NavigationControl, FullscreenControl, ScaleControl, GeolocateControl } from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import supercluster from "supercluster";

// Cluster layer for displaying clusters on the map
const clusterLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'farms',
  filter: ['>', ['get', 'point_count'], 1], // Only apply to clusters with more than 1 point
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#51bbd6', 10,   // Light blue for small clusters
      '#f1f075', 50,   // Yellow for medium clusters
      '#f28cb1'        // Pink for large clusters
    ],
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      15,  // Small clusters
      20,  // Medium clusters
      25   // Large clusters
    ]
  }
};

// Layer for displaying point counts per cluster
const pointCountLayer = {
  id: 'point-count',
  type: 'symbol',
  source: 'farms',
  filter: ['>', ['get', 'point_count'], 1], // Only apply to clusters with more than 1 point
  layout: {
    'text-field': ['get', 'point_count'],  // Display the point count in the cluster
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  },
  paint: {
    'text-color': '#000'  // Black text for readability
  }
};

// Layer for individual (unclustered) points
const unclusteredPointLayer = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'farms',
  filter: ['!', ['has', 'point_count']], // Only apply to non-clustered points
  paint: {
    'circle-color': '#11b4da',       // Blue color for individual points
    'circle-radius': 8,              // Size of individual points
    'circle-stroke-width': 1,        // Stroke around the circle
    'circle-stroke-color': '#fff'    // White stroke
  }
};

// Text for individual points (should display "1")
const unclusteredPointCountLayer = {
  id: 'unclustered-point-count',
  type: 'symbol',
  source: 'farms',
  filter: ['!', ['has', 'point_count']],  // Only apply to non-clustered points
  layout: {
    'text-field': '1',  // Display "1" for individual points
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12
  },
  paint: {
    'text-color': '#000'  // Black text for readability
  }
};

const FullScreenMap = ({ farmLocations, popupInfo, setPopupInfo, apiKey }) => {
  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 5
  });
  const [clusters, setClusters] = useState(null);

  // Calculate centroid of all farm locations
  const centroid = useMemo(() => {
    if (farmLocations.length === 0) return { lon: 0, lat: 0 };

    const totalCoords = farmLocations.reduce(
      (acc, farm) => {
        const lat = Number(farm.lat);
        const lon = Number(farm.lon);

        if (!isNaN(lat) && !isNaN(lon)) {
          acc.lat += lat;
          acc.lon += lon;
          acc.count += 1;
        }
        return acc;
      },
      { lat: 0, lon: 0, count: 0 }
    );

    return {
      lat: totalCoords.lat / totalCoords.count,
      lon: totalCoords.lon / totalCoords.count
    };
  }, [farmLocations]);

  // Generate points and create supercluster instance
  const points = useMemo(() => farmLocations.map((farm) => ({
    type: "Feature",
    properties: { farmId: farm.id, name: farm.name },
    geometry: {
      type: "Point",
      coordinates: [Number(farm.lon), Number(farm.lat)]
    }
  })), [farmLocations]);

  const clusterIndex = useMemo(() => {
    const index = new supercluster({
      radius: 100,
      maxZoom: 16
    });
    index.load(points);
    return index;
  }, [points]);

  // Update clusters based on view
  const updateClusters = useCallback(() => {
    const bbox = [
      viewState.bounds?._sw?.lng || -180,
      viewState.bounds?._sw?.lat || -90,
      viewState.bounds?._ne?.lng || 180,
      viewState.bounds?._ne?.lat || 90
    ];
    const zoom = Math.floor(viewState.zoom);
    setClusters(clusterIndex.getClusters(bbox, zoom));
  }, [viewState, clusterIndex]);

  useEffect(() => {
    updateClusters();
  }, [viewState, updateClusters]);

  // Set viewState based on the centroid of the farm locations
  useEffect(() => {
    if (centroid.lat && centroid.lon) {
      setViewState({
        longitude: centroid.lon,
        latitude: centroid.lat,
        zoom: 6 // Adjust this zoom level as needed
      });
    }
  }, [centroid]);

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <Map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        mapboxAccessToken={apiKey}
        style={{ width: "100%", height: "100%" }}
        onLoad={() => updateClusters()}
      >
        <Source
          id="farms"
          type="geojson"
          data={{
            type: "FeatureCollection",
            features: clusters || []
          }}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={50}
        >
          <Layer {...clusterLayer} />
          <Layer {...pointCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <Layer {...unclusteredPointCountLayer} />
        </Source>

        {popupInfo && (
          <Popup
            longitude={Number(popupInfo.lon)}
            latitude={Number(popupInfo.lat)}
            onClose={() => setPopupInfo(null)}
          >
            <div>{popupInfo.name}</div>
          </Popup>
        )}

        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
      </Map>
    </div>
  );
};

export default FullScreenMap;
