import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material"; // Import MUI Grid
import { useNavigate, useLocation } from "react-router";
import { useUserAuth } from "../context/UserAuthContext";
import Header from "./Header";
import FullScreenMap from "./FullScreenMap";

const Home = () => {
  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const [idToken, setIdToken] = useState("");
  const location = useLocation();
  const { userData } = location.state || {}; // Access userData from location state
  const [farmLocations, setFarmLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);

  const [viewState, setViewState] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 10,
  });

  useEffect(() => {
    const fetchIdToken = async () => {
      if (user) {
        console.log('User object:', user); // Log user to see its structure
        try {
          const token = await user.getIdToken();
          console.log("ID Token fetched:", token);
          setIdToken(token);
        } catch (error) {
          console.log("Error fetching ID token:", error);
        }
      } else {
        console.log("No user object found");
      }
    };

    fetchIdToken();
  }, [user]);


  useEffect(() => {
    const fetchFarmData = async () => {
      if (idToken && userData && userData.farms) {
        const promises = userData.farms.map(async (farm) => {
          try {
            const response = await fetch(`http://api.isabel.io/farm/${farm.farm_id}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`, // Pass ID token
              },
            });
            if (!response.ok) {
              throw new Error(`Failed to fetch data for farm ${farm.farm_id}`);
            }
            const farmData = await response.json();

            // Parse the coordinates before returning the farm data
            const lat = parseCoordinate(farmData.lat);
            const lon = parseCoordinate(farmData.lon);

            return {
              id: farm.farm_id,
              lat,
              lon,
              area: farmData.area,
              area_unit: farmData.area_unit,
              type: farmData.farm_type,
              name: farmData.name,
              city: farmData.city,
              state: farmData.state,
              country: farmData.country
            };
          } catch (error) {
            console.log(`Error fetching farm ${farm.farm_id} data:`, error);
            return null;
          }
        });

        const farmResults = await Promise.all(promises);
        const validFarms = farmResults.filter((farm) => farm !== null);
        setFarmLocations(validFarms);
        setLoading(false);

        // Center the map on the first valid farm's coordinates
        if (validFarms.length > 0) {
          const firstFarm = validFarms[0];
          setViewState({
            latitude: firstFarm.lat,
            longitude: firstFarm.lon,
            zoom: 10,
          });
        }
      }
    };

    if (idToken && userData) {
      fetchFarmData();
    }
  }, [idToken, userData]);

  // Parse coordinate function to handle degrees and cardinal directions
  const parseCoordinate = (coordinateStr) => {
    if (!coordinateStr) return NaN;

    // Remove non-numeric characters (e.g., °, N, E, S, W)
    const cleanStr = coordinateStr.replace(/[^\d.-]/g, '');
    const direction = coordinateStr.slice(-1);

    let coordinate = parseFloat(cleanStr);

    // Invert the coordinate if it's in the Southern or Western hemisphere
    if (direction === 'S' || direction === 'W') {
      coordinate = -coordinate;
    }

    return coordinate;
  };

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Grid container direction="column" style={{ height: "100vh", width: "100vw" }}>
      {/* Header Grid */}
      <Grid item xs="auto" style={{ zIndex: 1000 }}>
        <Header onLogout={handleLogout} idToken={idToken} farmLocations={farmLocations} userData={userData} />
      </Grid>

      {/* Full-Screen Map Grid */}
      <Grid item xs style={{ flexGrow: 1, marginTop: '64px', width: '100%' }}>
        {!loading && (
          <FullScreenMap
            viewState={viewState}
            setViewState={setViewState}
            farmLocations={farmLocations}
            popupInfo={popupInfo}
            setPopupInfo={setPopupInfo}
            apiKey={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Home;
